<template>
  <div id="support">
    <section class="bg-gradient-bottom bg-xl-gradient">
      <div class="container position-relative">
        <div class="jumbotron">
          <img
            class="support-girl"
            src="https://static.fasney.me/sff/fasney/image/support/support-girl.png"
            alt="fasney 財富指南"
          >
          <img
            class="support-walk"
            src="https://static.fasney.me/sff/fasney/image/support/support-walk.png"
            alt="support 財富指南"
          >
          <div class="support-title">
            <h2>財富指南</h2>
            <strong>SUPPORT</strong>
            <p class="mb-0">
              做你最可靠的智囊團
            </p>
            <p>支持你每一個最任性的決定</p>
          </div>
        </div>
      </div>
    </section>
    <section class="support-group container">
      <SupportCard
        class="col-md-6 col-xl-4"
        image-link="InstalmentPost"
        post-time="01 Sep 2020"
      >
        <template v-slot:support-img>
          <img
            src="https://static.fasney.me/sff/fasney/image/support/instalment.jpg"
            class="support-img"
            alt="使用慢慢還功能，要怎麼分期付款?"
          >
        </template>
        <template v-slot:support-title>
          <h3>
            使用慢慢還功能 <br>
            要怎麼分期付款?
          </h3>
        </template>
      </SupportCard>
      <SupportCard
        class="col-md-6 col-xl-4"
        image-link="PostpayPost"
        post-time="01 Sep 2020"
      >
        <template v-slot:support-img>
          <img
            src="https://static.fasney.me/sff/fasney/image/support/postpay.jpg"
            class="support-img"
            alt="fasney 不使用信用卡 為什麼可以延後付款"
          >
        </template>
        <template v-slot:support-title>
          <h3>
            不使用信用卡 <br>
            為什麼可以延後付款?
          </h3>
        </template>
      </SupportCard>
      <SupportCard
        class="col-md-6 col-xl-4"
        image-link="ReviewPost"
        post-time="01 Sep 2020"
      >
        <template v-slot:support-img>
          <img
            src="https://static.fasney.me/sff/fasney/image/support/review.jpg"
            class="support-img"
            alt="Fasney要怎麼審核?"
          >
        </template>
        <template v-slot:support-title>
          <h3>
            fasney要怎麼審核? <br>
            會不會很嚴格？
          </h3>
        </template>
      </SupportCard>
      <SupportCard
        class="col-md-6 col-xl-4"
        image-link="SuitPost"
        post-time="01 Sep 2020"
      >
        <template v-slot:support-img>
          <img
            src="https://static.fasney.me/sff/fasney/image/support/suit.jpg"
            class="support-img"
            alt="我適合使用Fasney嗎?"
          >
        </template>
        <template v-slot:support-title>
          <h3>
            我是金融小白 <br>
            我適合使用fasney嗎?
          </h3>
        </template>
      </SupportCard>
    </section>
  </div>
</template>

<script>
import SupportCard from '@/components/support/SupportCard'

export default {
  name: 'AllSupportPost',
  components: {
    SupportCard
  }
}
</script>
