<template>
  <section class="container support-post">
    <div class="col-xl-8">
      <img
        src="https://static.fasney.me/sff/fasney/image/support/review.jpg"
        class="support-post-img"
        alt="postpay"
      >
    </div>
    <div class="col-xl-8 text-left">
      <div class="card-post-time">
        <img
          src="https://static.fasney.me/sff/fasney/image/news/time.svg"
          alt="time"
        >
        <small class="pt-1">01 Sep 2020</small>
      </div>
      <h3 class="post-title">
        我是金融小白
        <br>
        <span style="font-family: 'Ubuntu', sans-serif;">fasney</span>要怎麼審核？會不會很嚴格？
      </h3>
      <div class="post-text">
        <p>
          <span>fasney</span>
          的審核已相對寬鬆及迅速，智能風控系統會朝「身分識別」、「償還能力」、「負債與負面資料」等面向進行蒐集、處理、分析；
        </p>
        <ul>
          <li class="pb-3">
            1.
            我們將對應的常態數字帶入收入與支出，試算現金流，但因fasney無法存取您的財產清單(財力證明)、實際收支，因此在不易評估及資料不足的狀況下會致電與您核對資料或通知補件再審。
          </li>
          <li>
            2.
            審核過程中不會調閱聯徵(信用報告)，但若您有信用瑕疵紀錄、近一年密集申辦金融服務、應付債務未正常繳款、債務金額高等情況，會使得評分較低，故可能無法為您提供後續服務，或需要您補充財力證明再評估。
          </li>
        </ul>
        <p>
          礙於有心人士會不斷嘗試、分析
          <span>fasney</span>的審核標準，造成
          <span>fasney</span>財務損失、影響會員未來使用服務的權益，因此請您見諒，
          <span>fasney</span>不會告知准駁與否的原因。
        </p>
      </div>
      <p class="text-other border-bottom">
        其他消息
      </p>
    </div>
    <div
      id="support"
      class="col-xl-8 px-0 text-left clearfix border-bottom"
    >
      <SupportCard
        image-link="SuitPost"
        post-time="01 Sep 2020"
      >
        <template v-slot:support-img>
          <img
            src="https://static.fasney.me/sff/fasney/image/support/suit.jpg"
            class="support-img"
            alt="我適合使用Fasney嗎?"
          >
        </template>
        <template v-slot:support-title>
          <h3>
            我是金融小白 <br>
            我適合使用fasney嗎?
          </h3>
        </template>
      </SupportCard>

      <SupportCard
        image-link="PostpayPost"
        post-time="01 Sep 2020"
      >
        <template v-slot:support-img>
          <img
            src="https://static.fasney.me/sff/fasney/image/support/postpay.jpg"
            class="support-img"
            alt="fasney 不使用信用卡 為什麼可以延後付款"
          >
        </template>
        <template v-slot:support-title>
          <h3>
            不使用信用卡 <br>
            為什麼可以延後付款?
          </h3>
        </template>
      </SupportCard>
    </div>
  </section>
</template>

<script>
import SupportCard from '@/components/support/SupportCard'
export default {
  name: 'ReviewPost',
  components: {
    SupportCard
  }
}
</script>
