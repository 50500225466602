<template>
  <article class="container support-post">
    <div class="col-xl-8">
      <img
        src="https://static.fasney.me/sff/fasney/image/support/instalment.jpg"
        class="support-post-img"
        alt="fasney 要怎麼分期付款"
      >
    </div>
    <div class="col-xl-8 text-left">
      <div class="card-post-time">
        <img
          src="https://static.fasney.me/sff/fasney/image/news/time.svg"
          alt="time"
        >
        <small class="pt-1">01 Sep 2020</small>
      </div>
      <h3 class="post-title">
        要怎麼分期付款
      </h3>
      <div class="post-text">
        <p>
          <span>fasney</span>有五個結帳週期並對應不同的繳款截止日，當您看到當期帳單的應繳金額超出您當月規劃的還款金額時，您就可以按下
          「靈活分期還款」將當期消費的應繳金額全數轉換至(慢慢還)，此服務為使用者付費，當您轉換成功後，從當期的繳款截止日便開始以餘額逐日計算利息(最晚要在一年內繳完，詳情看
          <span>App</span>)。
        </p>
        <p>
          <span>fasney</span>不將慢慢還以分期付款命名，是因為我們認為科技會讓金融服務更有彈性，當您手頭寬裕時就可提早還款，減少利息支出，您要一個禮拜繳一次、一天繳三次都不是問題，不用再被傳統固定的期數、期付款綁架，拿回自主權。
          (還是要依照帳單的週期及金額繳款哦！)
        </p>
      </div>
      <p class="text-other border-bottom">
        其他消息
      </p>
    </div>
    <div
      id="support"
      class="col-xl-8 px-0 text-left clearfix border-bottom"
    >
      <SupportCard
        image-link="ReviewPost"
        post-time="01 Sep 2020"
      >
        <template v-slot:support-img>
          <img
            src="https://static.fasney.me/sff/fasney/image/support/review.jpg"
            class="support-img"
            alt="Fasney要怎麼審核?"
          >
        </template>
        <template v-slot:support-title>
          <h3>
            fasney要怎麼審核? <br>
            會不會很嚴格？
          </h3>
        </template>
      </SupportCard>

      <SupportCard
        image-link="SuitPost"
        post-time="01 Sep 2020"
      >
        <template v-slot:support-img>
          <img
            src="https://static.fasney.me/sff/fasney/image/support/suit.jpg"
            class="support-img"
            alt="我適合使用Fasney嗎?"
          >
        </template>
        <template v-slot:support-title>
          <h3>
            我是金融小白 <br>
            我適合使用fasney嗎?
          </h3>
        </template>
      </SupportCard>
    </div>
  </article>
</template>

<script>
import SupportCard from '@/components/support/SupportCard'

export default {
  name: 'InstalmentPost',
  components: {
    SupportCard
  }
}
</script>
