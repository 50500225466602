<template>
  <section class="container support-post">
    <div class="col-xl-8">
      <img
        src="https://static.fasney.me/sff/fasney/image/support/suit.jpg"
        class="support-post-img"
        alt="我適合使用 fasney 嗎?"
      >
    </div>
    <div class="col-xl-8 text-left">
      <div class="card-post-time">
        <img
          src="https://static.fasney.me/sff/fasney/image/news/time.svg"
          alt="time"
        >
        <small class="pt-1">01 Sep 2020</small>
      </div>
      <h3 class="post-title">
        我適合使用
        <span style="font-family: 'Ubuntu', sans-serif;">fasney</span>嗎?
      </h3>
      <div class="post-text">
        <p>
          <span>fasney</span>的初衷是服務「金融小白」，填補傳統金融服務的斷層，讓金融小白從生活開銷中建立信用紀錄。
          日後若有額度較大的信貸、車貸、房貸需求，就能透過<span>fasney</span>向策略合作銀行申請，除了核准機率提高，也能在銀行間建立正向的紀錄。
        </p>
        <p>
          如果您本身已持有信用卡，也可將
          <span>fasney</span>作為理財規劃的工具之一，如消費額度分配、優惠選擇…等。
        </p>
      </div>
      <p class="text-other border-bottom">
        其他消息
      </p>
    </div>
    <div
      id="support"
      class="col-xl-8 px-0 text-left clearfix border-bottom"
    >
      <SupportCard
        image-link="PostpayPost"
        post-time="01 Sep 2020"
      >
        <template v-slot:support-img>
          <img
            src="https://static.fasney.me/sff/fasney/image/support/postpay.jpg"
            class="support-img"
            alt="fasney 不使用信用卡 為什麼可以延後付款"
          >
        </template>
        <template v-slot:support-title>
          <h3>
            不使用信用卡 <br>
            為什麼可以延後付款?
          </h3>
        </template>
      </SupportCard>
      <SupportCard
        image-link="InstalmentPost"
        post-time="01 Sep 2020"
      >
        <template v-slot:support-img>
          <img
            src="https://static.fasney.me/sff/fasney/image/support/instalment.jpg"
            class="support-img"
            alt="使用慢慢還功能，要怎麼分期付款?"
          >
        </template>
        <template v-slot:support-title>
          <h3>
            使用慢慢還功能 <br>
            要怎麼分期付款?
          </h3>
        </template>
      </SupportCard>
    </div>
  </section>
</template>

<script>
import SupportCard from '@/components/support/SupportCard'
export default {
  name: 'SuitPost',
  components: {
    SupportCard
  }
}
</script>
