<template>
  <article class="container support-post">
    <div class="col-xl-8">
      <img
        src="https://static.fasney.me/sff/fasney/image/support/postpay.jpg"
        class="support-post-img"
        alt="fasney 不使用信用卡 為什麼可以延後付款"
      >
    </div>
    <div class="col-xl-8 text-left">
      <div class="card-post-time">
        <img
          src="https://static.fasney.me/sff/fasney/image/news/time.svg"
          alt="time"
        >
        <small class="pt-1">01 Sep 2020</small>
      </div>
      <h3 class="post-title">
        不使用信用卡
        <br>為什麼可以延後付款?
      </h3>
      <div class="post-text">
        <p>
          <span>fasney</span>在您申請加入會員後，將相關數據輸入智能風控系統，系統就會給予一個僅限消費使用的信用額度，
          這個額度可以讓您在合作店家選擇使用
          <span>fasney</span>付款，享有免當場付現金、零利率就先取貨，等15~45天後帳單來了再付款。
        </p>
        <p>
          聽起來跟使用信用卡很像，但只要您有手機就可在
          <span>fasney App</span>上申請額度，審核通過立即使用，不用等待掛號寄送實體卡片哦！這不是黑科技，我們只是把
          店家向您收款的權利(債權)買了過來，因此原本要給店家的款項，等
          <span>App</span>上的帳單出現再付給 <span>fasney</span>就好！
        </p>
        <p>
          上述的運作架構為民法第五節「債之移轉」，消費者和店家先成立買賣契約(交易、訂單)，
          <span>fasney</span>再向店家購買這筆契約的收款權利(應收帳款債權)，因此
          <span>fasney</span>要付購買債權的款項給店家，同時債權人也從店家轉變成
          <span>fasney</span>，所以消費者的付款對象也從店家轉變成
          <span>fasney</span>囉！過程中消費者並不會得到現金。
        </p>
        <p>
          雖然合約架構和無卡分期/免卡分期相同，但
          <span>fasney</span>希望能將讓該服務回到消費支付的本質，以消費者體驗為出發點，改善風評不佳、資訊不透明的負面體驗，更不是店家
          用以銷售高單價商品給「金融小白」，賺取不合理利潤的工具，使用者也不必被貼上「不聰明才用無卡分期」的標籤。
        </p>
      </div>
      <p class="text-other border-bottom">
        其他消息
      </p>
    </div>
    <div
      id="support"
      class="col-xl-8 px-0 text-left clearfix border-bottom"
    >
      <SupportCard
        image-link="InstalmentPost"
        post-time="01 Sep 2020"
      >
        <template v-slot:support-img>
          <img
            src="https://static.fasney.me/sff/fasney/image/support/instalment.jpg"
            class="support-img"
            alt="使用慢慢還功能，要怎麼分期付款?"
          >
        </template>
        <template v-slot:support-title>
          <h3>
            使用慢慢還功能 <br>
            要怎麼分期付款?
          </h3>
        </template>
      </SupportCard>

      <SupportCard
        image-link="ReviewPost"
        post-time="01 Sep 2020"
      >
        <template v-slot:support-img>
          <img
            src="https://static.fasney.me/sff/fasney/image/support/review.jpg"
            class="support-img"
            alt="Fasney要怎麼審核?"
          >
        </template>
        <template v-slot:support-title>
          <h3>
            fasney要怎麼審核? <br>
            會不會很嚴格？
          </h3>
        </template>
      </SupportCard>
    </div>
  </article>
</template>

<script>
import SupportCard from '@/components/support/SupportCard'
export default {
  name: 'PostpayPost',
  components: {
    SupportCard
  }
}
</script>
