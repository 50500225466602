<template>
  <div class="support-card">
    <router-link :to="{ name: imageLink }">
      <slot name="support-img" />
    </router-link>
    <div class="support-content">
      <div class="card-post-time">
        <img
          src="https://static.fasney.me/sff/fasney/image/news/time.svg"
          alt="time"
        >
        <small class="pt-1">{{ postTime }}</small>
      </div>
      <slot name="support-title" />
      <router-link
        class="btn btn-more rounded-pill"
        :to="{ name: imageLink }"
      >
        了解更多
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SupportCard',
  props: {
    imageLink: {
      type: String,
      default: '',
      required: true
    },
    postTime: {
      type: String,
      default: '',
      required: true
    }
  }
}
</script>
